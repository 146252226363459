import Axios from 'axios';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { Roller } from 'react-css-spinners';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { StyledContactForm, StyledFooter, StyledNavigationBar } from '@/components';
import { COLORS } from '@/constants';
import Layout from '@/styles';

const LoadingContent = styled.div`
  padding-top: 120px;
  padding-bottom: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const CookiesPolicyLayout = styled.div`
  .navbar {
    &.--transparent {
      background-color: ${COLORS.NAVIGATION_BAR_BACKGROUND};
    }
  }
`;

const CookiesPolicyContent = styled.div`
  padding-top: 120px;
  padding-bottom: 60px;
  max-width: 1200px;
  margin: auto;

  .header, h3 {
    color: ${COLORS.COLOR_SECONDARY};
    font-weight: bold;
  }

  .header:not(:first-of-type) {
    margin-top: 34px;
  }

  @media (max-width: 1260px) {
    margin: 0 36px;
  }
`;

const CookiesPolicy = (): JSX.Element => {
  const { t } = useTranslation();
  const [content, setContent] = useState('');
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Axios.get(`${process.env.GATSBY_REST_API_ENDPOINT}/privacy-policy`)
      .then((response) => {
        setContent(response.data);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{ t('common:page-titles.cookies-policy') }</title>
      </Helmet>
      <Layout>
        <CookiesPolicyLayout>
          <StyledNavigationBar
            darkMenuIcon
            initialValue="NONE"
          />
          { loading
            ? (
              <LoadingContent>
                <Roller
                  color={`${COLORS.COLOR_SECONDARY}`}
                  size={40}
                />
              </LoadingContent>
            )
            : <CookiesPolicyContent>{ parse(content) }</CookiesPolicyContent> }
          <StyledContactForm />
          <StyledFooter />
        </CookiesPolicyLayout>
      </Layout>
    </>
  );
};

export default CookiesPolicy;
